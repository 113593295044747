<template>
  <div class="cpt-MMC_Gimbal_L60" :style="containerStyle">
    <div class="hd" v-interact>
      <div class="title">探照灯</div>
      <div @click="$emit('close')" class="close">关闭</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">控制速度</div>
          <div class="input-box">
            <div class="speedC">
              <div
                class="speed"
                :style="
                  control_speed == index + 1
                    ? 'background: #FFFFFF;color: #333333;'
                    : ''
                "
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                @click="control_speed = index + 1"
              >
                {{ index + 1 }}
              </div>
            </div>
            <!-- <el-select
              v-model="control_speed"
              size="mini"
              placeholder="请选择控制速度"
            >
              <el-option
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                :label="index + 1"
                :value="index + 1"
              ></el-option>
            </el-select> -->
          </div>
        </div>
        <!--  -->
        <div class="form-item">
          <div class="label-box">模式</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_mode" v-model="mode">
              <el-radio
                size="mini"
                v-for="item in drd.mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <!--  -->
        <div class="form-item">
          <div class="label-box">功率</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_pow_mode" v-model="pow_mode">
              <el-radio
                size="mini"
                v-for="item in drd.pow_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">联控模式</div>
          <div class="input-box">
            <el-radio-group
              @change="handle_change_control_mode"
              v-model="control_mode"
            >
              <el-radio
                size="mini"
                v-for="item in drd.control_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">灯光模式</div>
          <div class="input-box">
            <el-radio-group
              @change="handle_change_light_mode"
              v-model="light_mode"
            >
              <el-radio
                size="mini"
                v-for="item in drd.light_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">亮度</div>
          <div class="input-box">
            <el-radio-group
              @change="handle_change_brightness_mode"
              v-model="brightness_mode"
            >
              <el-radio
                size="mini"
                v-for="item in drd.brightness_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="rocker" ref="rocker">
          <div class="shangUp" @mousedown="fangxiang('up')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"></div>
          <div class="xaiUp" @mousedown="fangxiang('down')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"></div>
          <div class="zuoUp" @mousedown="fangxiang('left')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"></div>
          <div class="youUp" @mousedown="fangxiang('right')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cal_crc_table, MMC_Gimbal_L60 } from "../utils";
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
    position: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      time: null,
      radio: 1,
      timer: null,
      control_speed: 3,
      mode: 1,
      zoom: 1,
      pitch: 0,
      yaw: 0,
      pow_mode: 0,
      control_mode: 0,
      light_mode: 0,
      brightness_mode: 0,
      record: false,

      drd: {
        mode: [
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
        pow_mode: [
          { id: 0, label: "普通" },
          { id: 1, label: "最高" },
        ],
        control_mode: [
          { id: 0, label: "关" },
          { id: 1, label: "开" },
        ],
        light_mode: [
          { id: 0, label: "关" },
          { id: 1, label: "开" },
          { id: 2, label: "闪" },
        ],
        brightness_mode: [
          { id: 0, label: "亮" },
          { id: 1, label: "高亮" },
          { id: 2, label: "极亮" },
        ],
      },
    };
  },
  watch: {
    payload: {
      handler(value) {
        this.resolve_payload(value);
      },
      deep: true,
    },
  },
  methods: {
    resolve_payload(buff) {
      let dataArray = new Uint8Array(buff);
      if (
        dataArray[0] === 0xa5 &&
        cal_crc_table(dataArray) === dataArray[dataArray.length - 1]
      ) {
        switch (dataArray[1]) {
          case 0x01:
            this.msg_key_status1(dataArray);
            break;
          case 0xf8:
            this.gcs_button_ctrl(dataArray);
            break;
        }
      }
    },
    gcs_button_ctrl(data) {
      let button = data[3];
      let status = data[4];

      switch (button) {
        case 4:
          this.gimbal_mode_ctrl(status);
          break;
        default:
          break;
      }
    },
    msg_key_status1(data) {
      this.gimbal_mode_ctrl(data[7] & 0x3);
    },
    gimbal_mode_ctrl(mode) {
      this.ptz_mode = mode;
    },
    handle_change_mode(value) {
      let buffer = MMC_Gimbal_L60.gimbal_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_light_mode(value) {
      let buffer = MMC_Gimbal_L60.gimbal_light_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_brightness_mode(value) {
      let buffer = MMC_Gimbal_L60.gimbal_brightness_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_zoom(value) {
      let buffer = MMC_Gimbal_L60.zoom(this.zoom + value);
      this.commit_directive(buffer);
      buffer = MMC_Gimbal_L60.zoom((this.zoom = 1));
      this.commit_directive(buffer);
    },
    handle_change_pitch() {
      let buffer = MMC_Gimbal_L60.change_pitch_angle(this.pitch);
      this.commit_directive(buffer);
    },
    handle_change_yaw() {
      let buffer = MMC_Gimbal_L60.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },
    handle_change_camera_mode(value) {
      let buffer = MMC_Gimbal_L60.camera_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_pow_mode(mode) {
      let buffer = MMC_Gimbal_L60.pow_mode_ctrl(mode);
      this.commit_directive(buffer);
    },
    handle_change_control_mode(mode) {
      let buffer = MMC_Gimbal_L60.control_mode_ctrl(mode);
      this.commit_directive(buffer);
    },
    handle_take_photo() {
      let { position } = this;
      let buffer = MMC_Gimbal_L60.take_photo(position || {});
      this.commit_directive(buffer);
    },
    handle_record() {
      this.record = !this.record;
      let buffer = MMC_Gimbal_L60.record(this.record);
      this.commit_directive(buffer);
    },
    commit_directive(buffer) {
      console.log(buffer);
      this.$emit("directive", buffer);
    },
    stopfxFn() {
      this.flag = false;
      clearTimeout(this.time);
      let buffer = null;
      buffer = MMC_Gimbal_L60.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(buffer);
      buffer = MMC_Gimbal_L60.gimbal_yaw_ctrl(0, this.control_speed);
      this.commit_directive(buffer);
    },
    fangxiang(type) {
      clearTimeout(this.time);
      let buffer = null;
      this.flag = true;
      if (this.flag) {
        this.time = setTimeout(() => {
          this.fangxiang(type);
        }, 500);
      }
      switch (type) {
        case "up":
          buffer = MMC_Gimbal_L60.gimbal_pitch_ctrl(1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "right":
          buffer = MMC_Gimbal_L60.gimbal_yaw_ctrl(1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "left":
          buffer = MMC_Gimbal_L60.gimbal_yaw_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "down":
          buffer = MMC_Gimbal_L60.gimbal_pitch_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
      }
      // this.timer = setTimeout(() => {
      //   clearTimeout(this.timer);
      //   buffer = MMC_Gimbal_L60.gimbal_pitch_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      //   buffer = MMC_Gimbal_L60.gimbal_yaw_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      // }, 500);
    },
    init_rocker() {
      let buffer = null;
      joystick({
        // zone: this.$refs["rocker"],
        // mode: "static",
        // position: { left: "50%", top: "50%" },
        // color: "#9bacbe",
        onstart: (type) => {
          switch (type) {
            case "up":
              buffer = MMC_Gimbal_L60.gimbal_pitch_ctrl(1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case "right":
              buffer = MMC_Gimbal_L60.gimbal_yaw_ctrl(1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case "left":
              buffer = MMC_Gimbal_L60.gimbal_yaw_ctrl(-1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case "down":
              buffer = MMC_Gimbal_L60.gimbal_pitch_ctrl(-1, this.control_speed);
              this.commit_directive(buffer);
          }
        },
        onend: () => {
          buffer = MMC_Gimbal_L60.gimbal_pitch_ctrl(0, this.control_speed);
          this.commit_directive(buffer);
          buffer = MMC_Gimbal_L60.gimbal_yaw_ctrl(0, this.control_speed);
          this.commit_directive(buffer);
        },
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_L60 {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  width: 500px;
  height: 300px;
  box-sizing: border-box;
  // padding: 10px 20px;
  .hd {
    display: flex;
    justify-content: space-between;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
    .title {
      font-size: 18px;
      color: #08c2d1;
      font-weight: bold;
      line-height: 2;
      margin-left: 20px;
    }
    .close {
      font-size: 18px;
      color: #08c2d1;
      font-weight: bold;
      line-height: 2;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .bd {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    .form-wrap {
      flex-shrink: 0;
      .form-item {
        display: flex;
        align-items: center;
        height: 40px;
        .label-box {
          color: #cbd0eb;
          width: 75px;
        }
        .btn-default {
          padding: 0 10px !important;
          background: #2aefed;
          border-color: #2aefed;
        }
        .input-box {
          display: flex;
          align-items: center;
        }
      }
    }
    .rocker-wrap {
      flex: 1;
      .item-group {
        display: flex;
        justify-content: space-evenly;
        .item-box {
          .icon-box {
            width: 32px;
            height: 32px;
            background: #08c2d1;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            .iconfont {
              font-size: 24px;
              color: #dce9ff;
              cursor: pointer;
            }
          }
        }
      }
      .rocker {
        margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url("~@/assets/images/accident/caozuo.png") no-repeat;
        background-size: 100% 100%;
        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;
    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #08c2d1;
        color: #dce9ff;
      }
    }
  }
  .el-input {
    width: 165px;
    margin-right: 5px;
    .el-input__inner {
      background: #000000;
      border: 1px solid #08c2d1;
      color: #dce9ff;
    }
  }
  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #08c2d1;
            background: #08c2d1;
          }
        }
      }
      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }
  .el-button {
    background: #2aefed;
    color: #000;
    border: none;
  }
}
/deep/.el-slider__bar {
  background: #fff;
}
.speedC {
  width: 203px;
  height: 24px;
  display: flex;
  background: rgba(13, 34, 79, 0.65);
  border: 1px solid #43deff;
  border-radius: 12px;
  .speed {
    width: 41px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }
}
.iconfont {
  color: #2aefed;
}
</style>